import { withApollo } from 'components/withApollo'
import { NextPage } from 'next'
import getStaticApolloProps from 'lib/getStaticApolloProps'
import React from 'react'
import PageError from 'components/pages/PageError'
import Loading from 'components/Loading'
import PageMeta from 'components/PageMeta'
import PageLayout from 'components/pages/PageLayout'

import ContentSection from './ContentSection'
import HeroSection from './HeroSection'
import { useBlogHomeQuery } from './types/query'

type Props = {}

type Params = {
  seoName: string
  skipCache?: string
}

function BlogHomePage() {
  const { data, error, loading } = useBlogHomeQuery({
    fetchPolicy: 'cache-and-network'
  })

  if (error) return <PageError>{error.message}</PageError>

  if (!data?.blogPosts && loading) return <Loading className="w-full h-72" />

  const blogPosts = data!.blogPosts!

  return (
    <>
      <PageMeta
        canonicalPath="/blog"
        description="Blog about best food trucks"
        title="Best Food Trucks | Blog"
      />
      <PageLayout fullWidth>
        <div className="w-full">
          <HeroSection />
          {blogPosts.map((blogPost) => (
            <div key={blogPost.id}>
              <ContentSection
                path={blogPost.customerPath}
                publishedAt={blogPost.publishedAt}
                section={blogPost.contentSections[0]}
                title={blogPost.title}
              />
            </div>
          ))}
        </div>
      </PageLayout>
    </>
  )
}

export default withApollo({ ssr: false })(BlogHomePage as NextPage)

export const getStaticProps = getStaticApolloProps<Props, Params>(
  BlogHomePage,
  {
    revalidate: 60 * 60 * 24 // 24 hours
  }
)
