import React from 'react'

const HeroSection = () => {
  return (
    <main className="lg:relative w-full bg-gray-100">
      <div className="mx-auto max-w-7xl w-full pt-8 pb-6 text-center lg:text-left px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900">
          Best Food Trucks Blog
        </h1>
      </div>
    </main>
  )
}

export default HeroSection
