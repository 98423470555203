import Image from 'components/Image'
import cn from 'classnames'
import Link from 'next/link'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import stripTimeZone from 'utils/stripTimeZone'
import React from 'react'
import ButtonLink from 'components/ui/ButtonLink'

import { BlogHomeQuery } from '../types/query'

type Props = {
  section: NonNullable<BlogHomeQuery['blogPosts'][0]['contentSections'][0]>
  title: string
  path: string
  publishedAt: string
}

const ContentSection = ({ section, title, path, publishedAt }: Props) => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto pt-8 pb-8 lg:pb-24 px-4 sm:px-6 lg:px-8 text-center flex flex-col">
        <div className="flex flex-row items-baseline mb-8 justify-between">
          <div className="text-left">
            <Link href={path}>
              <h2 className="text-left text-2xl font-semibold underline mb-2">
                {title}
              </h2>
            </Link>
            <span className="italic text-gray-600 text-sm mb-2 block">
              {format(parseISO(stripTimeZone(publishedAt)), 'd MMMM y')}
            </span>
          </div>
          <div className="block md:hidden">
            <ButtonLink color="primary" href={path} variant="outline">
              Read full
            </ButtonLink>
          </div>
        </div>
        <div className={cn('flex flex-row-reverse md:flex-row gap-4')}>
          <div
            className="prose lg:prose-lg flex-grow w-1/2 max-w-full text-justify overflow-y-hidden max-h-48"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: section.content.html
            }}
            style={{
              maskImage: 'linear-gradient(black, 1%, black, 60%, transparent)',
              WebkitMaskImage:
                'linear-gradient(black, 1%, black, 60%, transparent)'
            }}
          />
          {section.image?.url && (
            <div className="flex-grow rounded-md w-12 md:w-32">
              <Image
                alt={section.imageAlt || title}
                className="rounded-3xl object-center object-cover"
                height={200}
                src={section.image?.url}
                width={200}
              />
            </div>
          )}
        </div>
        <div className="justify-center md:justify-start hidden md:flex">
          <ButtonLink color="primary" href={path} variant="outline">
            Read full
          </ButtonLink>
        </div>
      </div>
    </div>
  )
}

export default ContentSection
