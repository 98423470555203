/* tslint:disable */
/* eslint-disable */
// @ts-ignore
import * as Types from '../../../../global-graphql-types'

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import * as ApolloReactHooks from 'utils/extendApolloHooks'
const defaultOptions = {} as const
export type BlogHomeQueryVariables = Types.Exact<{ [key: string]: never }>

export type BlogHomeQuery = {
  __typename: 'Query'
  blogPosts: Array<{
    __typename: 'BlogPost'
    id: number
    title: string
    publishedAt: any
    customerPath: string
    contentSections: Array<{
      __typename: 'ContentSection'
      id: number
      position: number
      imageAlt: string | null
      content: { __typename: 'HtmlRecord'; html: string }
      image: { __typename: 'ImageAttachment'; url: string } | null
    }>
  }>
}

export const BlogHomeQueryDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BlogHomeQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'blogPosts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'published' },
                value: { kind: 'BooleanValue', value: true }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customerPath' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentSections' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' }
                    }
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'position' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageAlt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'html' }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'url' },
                              name: { kind: 'Name', value: 'variantUrl' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'height' },
                                  value: { kind: 'IntValue', value: '600' }
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'width' },
                                  value: { kind: 'IntValue', value: '600' }
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'format' },
                                  value: { kind: 'EnumValue', value: 'jpg' }
                                }
                              ]
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown) as DocumentNode

/**
 * __useBlogHomeQuery__
 *
 * To run a query within a React component, call `useBlogHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogHomeQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlogHomeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BlogHomeQuery,
    BlogHomeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<BlogHomeQuery, BlogHomeQueryVariables>(
    BlogHomeQueryDocument,
    options
  )
}
export function useBlogHomeQueryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BlogHomeQuery,
    BlogHomeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<BlogHomeQuery, BlogHomeQueryVariables>(
    BlogHomeQueryDocument,
    options
  )
}
export type BlogHomeQueryHookResult = ReturnType<typeof useBlogHomeQuery>
export type BlogHomeQueryLazyQueryHookResult = ReturnType<
  typeof useBlogHomeQueryLazyQuery
>
export type BlogHomeQueryQueryResult = Apollo.QueryResult<
  BlogHomeQuery,
  BlogHomeQueryVariables
>
